import React, {Component} from "react";
import Button from 'react-bootstrap/Button';
import {generateCardSelection, generateWinningEstimate} from "./CardUtils";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import PokerCard from "./PokerCard";
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import {generateDeck} from "./CardUtils";
import {generateGrid} from "./CardUtils";
import {generateCustomVPIP} from "./CardUtils";
import InputGroup from "react-bootstrap/InputGroup";

export default class RangeSimulatorContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            approvedContent: true,
            deck: [],
            winsAndLosses: [],
            totalHands: 0,
            range: [],
            customRange: [],
            boardCards: [],
            heroCards: [],
            usedCards: [],
            showCardSelectModal: false,
            cardEdit: null,
            VPIP: 50,
            inputVPIP: 50,
            typing: false,
            typingTimeout: 0,
            hideMenu: false,
            showHeroRankingModal: false,
            showHeroWinningModal: false,
            showOpponentRankingModal: false,
            showOpponentWinningModal: false,
            showOpponentVPIPModal: false,
            simulationDifficulty: 2,
            rangeOnlyPairs: false,
            rangeOnlySuited: false,
        }
        this.generateUsedCards = this.generateUsedCards.bind(this);
        this.handleCardSelectClose = this.handleCardSelectClose.bind(this);
        this.handleHeroRankingModalClose = this.handleHeroRankingModalClose.bind(this);
        this.handleHeroWinningModalClose = this.handleHeroWinningModalClose.bind(this);
        this.handleOpponentRankingModalClose = this.handleOpponentRankingModalClose.bind(this);
        this.handleOpponentWinningModalClose = this.handleOpponentWinningModalClose.bind(this);
        this.showOpponentRankingModal = this.showOpponentRankingModal.bind(this);
        this.showOpponentWinningModal = this.showOpponentWinningModal.bind(this);
        this.showHeroRankingModal = this.showHeroRankingModal.bind(this);
        this.showHeroWinningModal = this.showHeroWinningModal.bind(this);
        this.showOpponentVPIPModal = this.showOpponentVPIPModal.bind(this);
        this.handleOpponentVPIPModal = this.handleOpponentVPIPModal.bind(this);
        this.changeCardEdit = this.changeCardEdit.bind(this);
        this.removeCard = this.removeCard.bind(this);
        this.addCard = this.addCard.bind(this);
        this.returnDecideCardButton = this.returnDecideCardButton.bind(this);
        this.returnDecideHeroCardButton = this.returnDecideHeroCardButton.bind(this);
        this.toggleCustomRange = this.toggleCustomRange.bind(this);
        this.debouncedVPIP = this.debouncedVPIP.bind(this);
        this.changeVPIP = this.changeVPIP.bind(this);
        this.generateCustomRange = this.generateCustomRange.bind(this);
        this.generateWinningEstimate = this.generateWinningEstimate.bind(this);
        this.newHand = this.newHand.bind(this);
        this.approveContent = this.approveContent.bind(this);
        this.resetGrid = this.resetGrid.bind(this);
        this.changeSimulationDifficulty = this.changeSimulationDifficulty.bind(this);
        this.toggleRangeOnlyPairs = this.toggleRangeOnlyPairs.bind(this);
        this.toggleRangeOnlySuited = this.toggleRangeOnlySuited.bind(this);
    }

    generateUsedCards() {
        const usedCards = [];
        for (const card of this.state.boardCards) {
            usedCards.push(card);
        }
        for (const card of this.state.heroCards) {
            usedCards.push(card);
        }
        this.setState({
            usedCards: usedCards,
        });
    }

    handleCardSelectClose() {
        this.setState({
            showCardSelectModal: false
        })
    }

    handleHeroRankingModalClose() {
        this.setState({
            showHeroRankingModal: false,
        })
    }

    handleHeroWinningModalClose() {
        this.setState({
            showHeroWinningModal: false,
        })
    }

    handleOpponentRankingModalClose() {
        this.setState({
            showOpponentRankingModal: false,
        })
    }

    handleOpponentWinningModalClose() {
        this.setState({
            showOpponentWinningModal: false,
        })
    }

    handleOpponentVPIPModal() {
        this.setState({
            showOpponentVPIPModal: false,
        })
    }

    showHeroRankingModal() {
        this.setState({
            showHeroRankingModal: true
        })
    }

    showHeroWinningModal() {
        this.setState({
            showHeroWinningModal: true
        })
    }

    showOpponentRankingModal() {
        this.setState({
            showOpponentRankingModal: true
        })
    }

    showOpponentWinningModal() {
        this.setState({
            showOpponentWinningModal: true
        })
    }

    showOpponentVPIPModal() {
        this.setState({
            showOpponentVPIPModal: true
        })
    }

    changeCardEdit(elem) {
        if (!elem || !elem?.target?.dataset?.value) {
            return;
        }
        this.setState({
            cardEdit: elem.target.dataset.value,
            showCardSelectModal: true
        })
    }

    removeCard(elem) {
        if (!elem || !elem?.target?.dataset?.value) {
            return;
        }
        this.setState({
            boardCards: this.state.boardCards.filter(card => {
                return card !== elem.target.dataset.value
            }),
            heroCards: this.state.heroCards.filter(card => {
                return card !== elem.target.dataset.value
            }),
            usedCards: this.state.usedCards.filter(card => {
                return card !== elem.target.dataset.value
            })
        }, () => {
            this.generateWinningEstimate();
        })
    }

    addCard(elem) {
        let show_modal = false;
        const board_cards = this.state.boardCards;
        const hero_cards = this.state.heroCards;

        switch (this.state.cardEdit) {
            case 'flop':
            case 'turn':
            case 'river':
                if (board_cards.length < 2) {
                    show_modal = true;
                }
                board_cards.push(elem.target.dataset.value);
                break;
            case 'hero':
                if (hero_cards.length < 1) {
                    show_modal = true;
                }
                hero_cards.push(elem.target.dataset.value);
                break;
            default:
                return;
        }

        this.setState({
            boardCards: board_cards,
            heroCards: hero_cards,
            showCardSelectModal: show_modal,
            hideMenu: true,
        }, () => {
            this.generateUsedCards();
            this.generateWinningEstimate();
        })
    }

    returnDecideCardButton() {
        const cards = this.state.boardCards.length;
        let text = '';
        let value = '';
        switch (true) {
            case cards <= 2:
                value = 'flop';
                text = 'Choose the flop';
                break;
            case cards === 3:
                value = 'turn';
                text = 'Choose the turn';
                break;
            case cards === 4:
                value = 'river';
                text = 'Choose the river';
                break;
            default:
                return null;
        }
        return <div className="btn btn-outline-dark poker-card" onClick={this.changeCardEdit} data-value={value} style={{justifyContent: 'center'}}>{text}</div>
    }

    returnDecideHeroCardButton() {
        const cards = this.state.heroCards.length;
        let text = '';
        let value = '';
        switch (true) {
            case cards === 0:
                value = 'hero';
                text = 'Choose hero cards';
                break;
            case cards === 1:
                value = 'hero';
                text = 'Choose hero card';
                break;
            default:
                return null;
        }
        return <Button className="btn btn-outline-dark poker-card" onClick={this.changeCardEdit} data-value={value}
                       style={{justifyContent: 'center'}}>{text}</Button>
    }

    toggleCustomRange(elem) {
        if (!elem || !elem?.target?.dataset?.range) {
            return;
        }

        const c = this.state.customRange;
        let cr = [];
        const tr = elem.target.dataset.range;

        if (!c.includes(tr)) {
            cr = c;
            cr.push(tr);
        } else {
            cr = c.filter((co) => {
                return co !== tr;
            })
        }

        this.setState({
            customRange: cr
        }, () => {
            this.generateCustomRange();
        })
    }

    debouncedVPIP(value) {

        if (!value || !value?.target?.value) {
            return;
        }
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        let vpip = Number(value.target.value);
        if (vpip < 1) {
            vpip = 1;
        }
        if (vpip > 100) {
            vpip = 100;
        }
        this.setState({
            inputVPIP: vpip,
            typing: false,
            typingTimeout: setTimeout(() => {
                this._changeVPIPFromInput();
                }, 500)
        });
    }

    _changeVPIPFromInput() {
        this.setState({
            VPIP: this.state.inputVPIP,
            hideMenu: true,
        }, () => {
            this.generateCustomRange();
        })
    }

    _handleFocus(elem) {
        if (!elem || !elem.target) {
            return;
        }
        elem.target.select();
    }

    _handleFocusOutside() {
        const elem = document.getElementById('inputVPIP');
        elem.select();
    }

    _changeVPIPToStyle(vpip) {
        switch(true) {
            case vpip >= 0 && vpip < 10: return 'Very tight player';
            case vpip >=10 && vpip < 20: return 'Tight player';
            case vpip >=20 && vpip < 30: return 'Balanced player';
            case vpip >=30 && vpip < 40: return 'Loose player';
            case vpip >=40 && vpip < 60: return 'Very loose player';
            case vpip >=60 && vpip <= 100: return 'Maniac player';
            default: return '';
        }
    }

    changeVPIP(elem) {
        if (!elem || !elem?.target?.value) {
            return;
        }
        let vpip = Number(elem.target.value);

        if (elem?.target?.dataset?.percent) {
            let correlated_vpip = Number(vpip + (vpip * Number(elem.target.dataset.percent)));
            if (correlated_vpip < 1) {
                correlated_vpip = 1;
            }
            if (correlated_vpip > 100) {
                correlated_vpip = 100;
            }
            vpip = correlated_vpip.toFixed(0);
        }

        if (elem?.target?.dataset?.add) {
            let correlated_vpip = Number(vpip + Number(elem.target.dataset.add));
            if (correlated_vpip < 1) {
                correlated_vpip = 1;
            }
            if (correlated_vpip > 100) {
                correlated_vpip = 100;
            }
            vpip = correlated_vpip.toFixed(0);
        }

        this.setState({
            VPIP: vpip,
            inputVPIP: vpip,
            hideMenu: true,
            showOpponentVPIPModal: false,
        }, () => {
            this.generateCustomRange();
        });
    }

    changeSimulationDifficulty(elem) {
        if (!elem || !elem?.target?.value || elem?.target?.value < 1 || elem?.target?.value > 10) {
            return;
        }
        this.setState({
            simulationDifficulty: Number(elem.target.value)
        }, () => {
            this.generateWinningEstimate();
        })
    }

    toggleRangeOnlyPairs() {
        this.setState({
            rangeOnlyPairs: !this.state.rangeOnlyPairs,
            rangeOnlySuited: false,
        }, () => {
            this.generateCustomRange();
        });
    }

    toggleRangeOnlySuited() {
        this.setState({
            rangeOnlySuited: !this.state.rangeOnlySuited,
            rangeOnlyPairs: false,
        }, () => {
            this.generateCustomRange();
        });
    }

    generateCustomRange() {
        this.setState({
            range: generateCustomVPIP(this.state.VPIP, this.state.customRange, this.state.rangeOnlyPairs, this.state.rangeOnlySuited)
        }, () => {
            this.generateWinningEstimate();
        });
    }

    generateWinningEstimate() {
        this.setState({
            winsAndLosses: generateWinningEstimate(this.state.heroCards, this.state.boardCards, this.state.deck, this.state.usedCards, this.state.range, this.state.simulationDifficulty)
        })
    }

    newHand() {
        this.setState({
            heroCards: [],
            boardCards: [],
            usedCards: []
        }, () => {
            this.generateCustomRange();
        })
    }

    approveContent() {
        this.setState({
            approvedContent: false,
        });
    }

    resetGrid() {
        this.setState({
            range: [],
            customRange: [],
        }, () => {
            this.generateCustomRange()
        })
    }

    componentDidMount() {
        this.setState({
            deck: generateDeck([])
        });
        this.generateCustomRange();
    }

    render() {
        const playerWins = ((this.state?.winsAndLosses?.wins + this.state?.winsAndLosses?.ties) / this.state?.winsAndLosses?.count) * 100;
        const playerOnlyWins = (this.state?.winsAndLosses?.wins / this.state?.winsAndLosses?.count) * 100;
        const playerOnlyTies = (this.state?.winsAndLosses?.ties / this.state?.winsAndLosses?.count) * 100;
        const opponentOnlyWins = (this.state?.winsAndLosses?.opponentWins / this.state?.winsAndLosses?.opponentCount) * 100;
        const opponentOnlyTies = (this.state?.winsAndLosses?.opponentTies / this.state?.winsAndLosses?.opponentCount) * 100;
        let playerLikelyHand = '';
        let opponentLikelyHand = '';
        if (this.state?.winsAndLosses?.handChances?.length && this.state?.winsAndLosses?.opponentHandChances?.length) {
            playerLikelyHand = this.state.winsAndLosses.handChances.sort((a,b) => {return a.count > b.count ? -1 : b.count > a.count ? 1 : 0}).map((hand) => {return {name: hand.name.charAt(0).toUpperCase() + hand.name.slice(1), chance: ((hand.count / this.state?.winsAndLosses?.count) * 100)}});
            opponentLikelyHand = this.state.winsAndLosses.opponentHandChances.sort((a,b) => {return a.count > b.count ? -1 : b.count > a.count ? 1 : 0}).map((hand) => {return {name: hand.name.charAt(0).toUpperCase() + hand.name.slice(1), chance: ((hand.count / this.state?.winsAndLosses?.opponentCount) * 100)}});
        }
        const opponentWins = ((this.state?.winsAndLosses?.opponentWins + this.state?.winsAndLosses?.opponentTies) / this.state?.winsAndLosses?.opponentCount) * 100;

        const boardCards = this.state.boardCards;
        const heroCards = this.state.heroCards;
        const deck = this.state.deck;
        const usedCards = this.state.usedCards;
        const cardSelect = <>
            <Row>
                <Col
                    className="card-select-flex">{generateCardSelection('h', deck, usedCards, this.addCard, this.removeCard)}</Col>
                <Col
                    className="card-select-flex">{generateCardSelection('d', deck, usedCards, this.addCard, this.removeCard)}</Col>
                <Col
                    className="card-select-flex">{generateCardSelection('s', deck, usedCards, this.addCard, this.removeCard)}</Col>
                <Col
                    className="card-select-flex">{generateCardSelection('c', deck, usedCards, this.addCard, this.removeCard)}</Col>
            </Row>
        </>
        return <Container fluid>
            {
                !this.state.hideMenu
                    && <Navbar className="bg-body-tertiary">
                        <Container>
                            <Navbar.Brand>Poker Range simulator - know-your-odds.com</Navbar.Brand>
                        </Container>
                    </Navbar>
            }

            <Row style={{marginTop: '10px', marginBottom: '80px'}}>
                <Col xs={12} xl={8}>
                    <Row className={"justify-content-center my-2"}>
                        <Col xs={12} sm={10} md={8} xl={6}>
                            <div className={"justify-content-between d-flex flex-column"}>
                                <Form.Range onChange={this.debouncedVPIP} min={1} max={100} value={this.state.inputVPIP} />
                                <InputGroup>
                                    <Button variant={"danger"} className="bg-danger-subtle text-danger" onClick={this.changeVPIP} value={this.state.VPIP} data-add={-5} size={"sm"}>-5</Button>
                                    <InputGroup.Text onClick={this._handleFocusOutside}>VPIP</InputGroup.Text>
                                    <Form.Control type={"number"} id={"inputVPIP"} value={this.state.inputVPIP} min={0} max={100} className={"text-center"} onChange={this.debouncedVPIP} onClick={this._handleFocus} onFocus={this._handleFocus} />
                                    <InputGroup.Text onClick={this._handleFocusOutside}>VPIP</InputGroup.Text>
                                    <Button variant={"success"} className="bg-success-subtle text-success" onClick={this.changeVPIP} value={this.state.VPIP} data-add={5} size={"sm"}>+5</Button>
                                </InputGroup>
                            </div>
                        </Col>
                    </Row>
                    <div className="table-container">
                        <div className="table-image"/>
                        <Row className={"justify-content-center"}>
                            <Col>
                                <Row className={"justify-content-center mb-4 mt-2"}>
                                    <Col xs={12} sm={12} md={10} xl={8}>
                                        <div className="d-flex mb-0 justify-content-center gap-2">
                                            <Col className="flex-column px-0 py-0 p-sm-2 bg-danger-subtle text-center rounded-2 d-flex justify-content-center shadow" style={{cursor:'pointer'}} onClick={this.showOpponentWinningModal}>
                                                {heroCards.length > 1 ? (
                                                    <>
                                                        <div className="py-0 p-sm-2 small">Opponent wins/ties:<br/><strong>{opponentWins.toFixed(2)} %</strong></div>
                                                        <div className="py-0 p-sm-2 small">Simulations<br /> {this.state.winsAndLosses.count}</div>
                                                    </>
                                                ) : <>
                                                    <div className="py-0 p-sm-2 small">Opponent wins/ties<br/><strong>0 %</strong></div>
                                                    <div
                                                        className="py-0 p-sm-2 small">Simulations<br/> {this.state.winsAndLosses.count}
                                                    </div>
                                                </>}
                                            </Col>
                                            <Col
                                                className="flex-column px-0 py-0 p-sm-2 bg-danger-subtle text-center rounded-2 d-flex justify-content-center shadow" style={{cursor:'pointer'}} onClick={this.showOpponentVPIPModal}>
                                            <div className="pt-2 small">Opponent</div>
                                                <div className="pb-2 p-1 small"><strong>{this._changeVPIPToStyle(this.state.VPIP)}</strong></div>
                                            </Col>
                                            <Col className="flex-column px-0 py-0 p-sm-2 bg-danger-subtle text-center rounded-2 d-flex justify-content-center shadow" style={{cursor:'pointer'}} onClick={this.showOpponentRankingModal}>
                                                {heroCards.length > 1 && (
                                                    <>
                                                        <div
                                                            className="py-0 p-2 small"
                                                            style={{fontWeight: 'bold'}}>{opponentLikelyHand[0]?.name} {opponentLikelyHand[0]?.chance.toFixed(2)}%
                                                        </div>
                                                        <div className="py-0 p-2 small">{opponentLikelyHand[1]?.name} {opponentLikelyHand[1]?.chance.toFixed(2)}%</div>
                                                        <div className="py-0 p-2 small">{opponentLikelyHand[2]?.name} {opponentLikelyHand[2]?.chance.toFixed(2)}%</div>
                                                    </>
                                                )}
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className={"justify-content-center mb-4"}>
                            <Col>
                                <div className="center-cards">
                                    {
                                        boardCards.map((card, index) => (
                                            <PokerCard key={index} card={card} removeCard={this.removeCard}/>
                                        ))
                                    }
                                    {this.returnDecideCardButton()}
                                </div>
                            </Col>
                        </Row>
                        <Row className={"justify-content-center my-4"}>
                            <Col>
                                <Row className={"justify-content-center mb-4 mt-2"}>
                                    <Col xs={12} sm={12} md={10} xl={8}>
                                        <div className="d-flex mb-0 justify-content-center gap-2">
                                            <Col className="flex-column px-0 py-0 py-sm-2 bg-success-subtle text-center rounded-2 d-flex justify-content-center shadow" style={{cursor:'pointer'}} onClick={this.showHeroWinningModal}>
                                                {heroCards.length > 1 ? (
                                                    <>
                                                        <div className="py-0 small p-sm-2">Hero wins/ties<br/><strong>{playerWins.toFixed(2)} %</strong></div>
                                                        <div className="py-0 small p-sm-2">Simulations<br/>{this.state.winsAndLosses.count}</div>
                                                    </>
                                                ) : <>
                                                    <div className="py-0 small p-sm-2">Hero wins/ties<br/><strong>0 %</strong></div>
                                                    <div
                                                        className="py-0 small p-sm-2">Simulations<br/>{this.state.winsAndLosses.count}
                                                    </div>
                                                </>
                                                }
                                            </Col>
                                            <Col
                                                className="flex-column px-0 py-0 text-center d-flex justify-content-center">
                                            <Col
                                                    className="flex-row px-0 py-0 bg-transparent text-center d-flex justify-content-center gap-2">
                                                    {
                                                        heroCards.map((card, index) => (
                                                            <PokerCard key={index} card={card}
                                                                       removeCard={this.removeCard}/>
                                                        ))
                                                    }
                                                    {this.returnDecideHeroCardButton()}
                                                </Col>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    marginTop: '5px'
                                                }}>
                                                    {(heroCards.length > 1 || boardCards.length > 0) && (
                                                        <Button variant={"danger"} onClick={this.newHand} size={"sm"}>Next hand</Button>)
                                                    }
                                                </div>
                                            </Col>

                                            <Col
                                                className="flex-column px-0 py-2 bg-success-subtle bg-gradient text-center rounded-2 d-flex justify-content-center shadow" style={{cursor:'pointer'}} onClick={this.showHeroRankingModal}>
                                                {heroCards.length > 1 && (
                                                    <>
                                                        <div
                                                            className={"py-0 small p-sm-2"}
                                                            style={{fontWeight: 'bold'}}>{playerLikelyHand[0]?.name} {playerLikelyHand[0]?.chance.toFixed(2)}%
                                                        </div>
                                                        <div className={"py-0 small p-sm-2"}>{playerLikelyHand[1]?.name} {playerLikelyHand[1]?.chance.toFixed(2)}%</div>
                                                        <div className={"py-0 small p-sm-2"}>{playerLikelyHand[2]?.name} {playerLikelyHand[2]?.chance.toFixed(2)}%</div>
                                                    </>
                                                )}
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={12} xl={4} className={"px-4"}>
                    <Row className="d-xl-none py-1"></Row>
                    <Row>
                        <Col>
                            {generateGrid(this.state.range, this.state.customRange, this.toggleCustomRange)}
                            {this.state.customRange.length > 0 && (
                                <div className="text-center" style={{marginTop: '5px'}}>
                                    <Button variant={"danger"} onClick={this.resetGrid} size={"sm"}>Reset
                                        selection</Button>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row className={"py-2"}>
                        <Col className={"text-center"}>
                            <Button onClick={this.toggleRangeOnlyPairs} variant={(this.state.rangeOnlyPairs ? 'success' : 'danger')} className={"mx-2"} size={"sm"}>Filter to only pairs</Button>
                            <Button onClick={this.toggleRangeOnlySuited} variant={(this.state.rangeOnlySuited ? 'success' : 'danger')} className={"mx-2"} size={"sm"}>Filter to only suited cards</Button>
                        </Col>
                    </Row>
                    <Row className={"pt-4"}>
                        <a href={"https://www.patreon.com/bePatron?u=118413397"} target={"_blank"} rel={"noreferrer"} className={"text-success"}>Support my work by becoming a member to my Patreon</a>
                    </Row>
                </Col>
            </Row>
            <Modal show={this.state.showCardSelectModal} onHide={this.handleCardSelectClose} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Card select</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {cardSelect}
                </Modal.Body>
            </Modal>
            <Modal show={this.state.approvedContent} size={"lg"}>
                <Modal.Header>
                    <Modal.Title>Approve the use of this content</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h1>Terms of Service - Poker Range simulator, know-your-odds.com</h1>
                    <p>Last Updated: 2024-09-02</p>
                    <p>Welcome to know-your-odds.com ("the Site"). By accessing and using the Site, you agree to comply
                        with and be bound by the following terms and conditions of use. If you do not agree with these
                        terms, please do not use the Site.</p>
                    <h2>1. Use of the Site</h2>
                    <p>1.1 The Site is provided for simulation purposes only. Any information, content, or materials on
                        the Site are not intended to be used for any real-world applications.</p>
                    <p>1.2 You acknowledge and agree that your use of the Site is at your own risk. The Site and its
                        content are provided on an "as is" and "as available" basis.</p>
                    <h2>2. User Responsibilities</h2>
                    <p>2.1 You are solely responsible for the use of the Site and any consequences that may arise from
                        such use.</p>
                    <p>2.2 You agree not to use the Site for any unlawful or prohibited purposes or in any way that
                        could damage, disable, overburden, or impair the Site.</p>
                    <h2>3. Disclaimer of Liability</h2>
                    <p>3.1 The Site and its operators, including but not limited to know-your-odds.com, its affiliates,
                        partners, and employees, shall not be held responsible for any damages, direct or indirect,
                        arising out of the use or inability to use the Site.</p>
                    <p>3.2 We do not guarantee the accuracy, completeness, or reliability of any information, content,
                        or materials on the Site. Any reliance on the Site is at your own risk.</p>
                    <h2>4. Intellectual Property</h2>
                    <p>4.1 All content on the Site, including but not limited to text, graphics, logos, images, and
                        software, is the property of know-your-odds.com or its content suppliers and is protected by
                        copyright and other intellectual property laws.</p>
                    <p>4.2 You may not reproduce, distribute, modify, transmit, or use the content of the Site for any
                        commercial or public purpose without the prior written consent of know-your-odds.com.</p>
                    <h2>5. Termination of Access</h2>
                    <p>5.1 We reserve the right to terminate or restrict your access to the Site, in whole or in part,
                        without notice, for any reason or no reason at our sole discretion.</p>
                    <h2>6. Changes to Terms</h2>
                    <p>6.1 We reserve the right to update or modify these terms of service at any time without prior
                        notice. Your continued use of the Site after any such changes constitutes your acceptance of the
                        new terms.</p>
                    <h2>7. Cookies</h2>
                    <p>7.1 The Site uses cookies to function properly.</p>
                    <h2>8. Gathering information</h2>
                    <p>8.1 The Site gathers information of the user, including but not limited to IP-address, location,
                        user-agent, date and time of loading the Site.</p>
                    <h2>9. Governing Law</h2>
                    <p>9.1 These terms of service are governed by and construed in accordance with the laws of
                        Finland.</p>
                    <p>By using the Site, you agree to these terms and conditions. If you have any questions or
                        concerns, please contact us at contact@know-your-odds.com.</p>
                    <Button onClick={this.approveContent}>I agree to the Terms of Service</Button>
                </Modal.Body>
            </Modal>
            <Modal show={this.state.showHeroRankingModal} onHide={this.handleHeroRankingModalClose} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Hero's rankings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {heroCards.length > 1 ? (
                        playerLikelyHand.map((item, index) => {
                            return <Row className="text-center" key={index}>
                                <Col className="text-end">{item?.name}</Col><Col
                                className="text-start">{item?.chance.toFixed(3)} %</Col>
                            </Row>;
                        }

                        )
                    ) : <p className={"py-2 text-center"}>Choose hero cards first</p>}
                    <p className={"pt-1 text-center"}><strong>Total simulations<br/>{this.state.winsAndLosses.count}</strong></p>
                    <Row>
                        <Col className={"text-end"} xs={3}>
                            <small>Less</small>
                        </Col>
                        <Col className={"text-center"}>
                            <Form.Range
                                onChange={this.changeSimulationDifficulty}
                                min={1}
                                max={10}
                                value={this.state.simulationDifficulty}
                            />
                        </Col>
                        <Col className={"text-start"} xs={3}>
                            <small>More</small>
                        </Col>
                    </Row>
                    <p className={"pt-3 text-center"}>The information above shows the hero's probability of hand rankings in the end of the hand. Simulation will take more time on the flop.</p>
                </Modal.Body>
            </Modal>
            <Modal show={this.state.showHeroWinningModal} onHide={this.handleHeroWinningModalClose} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Hero wins/ties</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {heroCards.length > 1 ? (
                        <>
                            <p className={"pt-4 text-center"}>Hero wins {playerOnlyWins.toFixed(2)}%</p>
                            <p className={"pb-2 text-center"}>Hero ties {playerOnlyTies.toFixed(2)}%</p>
                        </>
                    ) : <p className={"py-2 text-center"}>Choose hero cards first</p>}
                    <p className={"pt-1 text-center"}><strong>Total simulations<br/>{this.state.winsAndLosses.count}</strong></p>
                    <Row>
                        <Col className={"text-end"} xs={2}>
                            <small>Less</small>
                        </Col>
                        <Col className={"text-center"}>
                            <Form.Range
                                onChange={this.changeSimulationDifficulty}
                                min={1}
                                max={10}
                                value={this.state.simulationDifficulty}
                            />
                        </Col>
                        <Col className={"text-start"} xs={2}>
                            <small>More</small>
                        </Col>
                    </Row>
                    <p className={"pt-3 text-center"}>The information above shows the hero's probability to win and tie in the end of the hand. Simulation will take more time on the flop.</p>
                </Modal.Body>
            </Modal>
            <Modal show={this.state.showOpponentRankingModal} onHide={this.handleOpponentRankingModalClose} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Opponent's rankings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {heroCards.length > 1 ? (
                        opponentLikelyHand.map((item, index) => {
                            return <Row className="text-center" key={index}>
                                <Col className="text-end">{item?.name}</Col>
                                <Col className="text-start">{item?.chance.toFixed(3)} %</Col>
                            </Row>;
                        })
                    ) : <p className={"py-2 text-center"}>Choose hero cards first</p>}
                    <p className={"pt-1 text-center"}><strong>Total simulations<br />{this.state.winsAndLosses.count}</strong></p>
                    <Row>
                        <Col className={"text-end"} xs={2}>
                            <small>Less</small>
                        </Col>
                        <Col className={"text-center"}>
                            <Form.Range
                                onChange={this.changeSimulationDifficulty}
                                min={1}
                                max={10}
                                value={this.state.simulationDifficulty}
                            />
                        </Col>
                        <Col className={"text-start"} xs={2}>
                            <small>More</small>
                        </Col>
                    </Row>
                    <p className={"pt-3 text-center"}>The information above shows your opponents probability of hand rankings in the end of the hand. Simulation will take more time on the flop.</p>
                </Modal.Body>
            </Modal>
            <Modal show={this.state.showOpponentWinningModal} onHide={this.handleOpponentWinningModalClose} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Opponent wins/ties</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {heroCards.length > 1 ? (
                        <>
                            <p className={"pt-4 text-center"}>Opponent wins {opponentOnlyWins.toFixed(2)}%</p>
                            <p className={"pb-2 text-center"}>Opponent ties {opponentOnlyTies.toFixed(2)}%</p>
                        </>
                    ) : <p className={"py-2 text-center"}>Choose hero cards first</p>}
                    <p className={"pt-1 text-center"}><strong>Total simulations<br />{this.state.winsAndLosses.count}</strong></p>
                    <Row>
                        <Col className={"text-end"} xs={2}>
                            <small>Less</small>
                        </Col>
                        <Col className={"text-center"}>
                            <Form.Range
                                onChange={this.changeSimulationDifficulty}
                                min={1}
                                max={10}
                                value={this.state.simulationDifficulty}
                            />
                        </Col>
                        <Col className={"text-start"} xs={2}>
                            <small>More</small>
                        </Col>
                    </Row>
                    <p className={"pt-3 text-center"}>The information above shows your opponent's probability to win and tie in the end of the hand. Simulation will take more time on the flop.</p>
                </Modal.Body>
            </Modal>
            <Modal show={this.state.showOpponentVPIPModal} onHide={this.handleOpponentVPIPModal} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>VPIP play styles</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className={"justify-content-center"}>
                        <Col>
                            <Row className={"justify-content-center"}>
                                <Col>
                                    <div className="d-flex flex-column justify-content-center gap-2">
                                        <Col className={"d-flex flex-column text-center"}>
                                            <p className={"py-1"}><strong>Very tight player</strong><br/>
                                                Opponent plays only the best hands. <br/>Value put in pot (VPIP) is
                                                under
                                                10.<br/>
                                                <Button variant={"success"} onClick={this.changeVPIP}
                                                        value={5} size={"sm"}>Select VPIP 5</Button>
                                            </p>
                                        </Col>
                                        <Col className={"d-flex flex-column text-center"}>
                                            <p className={"py-1"}><strong>Tight player</strong><br/>
                                                Opponent plays little bit wider than the very tight player. <br/>Value
                                                put in
                                                pot
                                                (VPIP)
                                                is between
                                                10 - 20.<br/>
                                                <Button variant={"success"} onClick={this.changeVPIP}
                                                        value={15} size={"sm"}>Select VPIP 15</Button>
                                            </p>
                                        </Col>
                                        <Col className={"d-flex flex-column text-center"}>
                                            <p className={"py-1"}><strong>Balanced player</strong><br/>
                                                Opponent's range varies depending on the situation. <br/>Value put in
                                                pot (VPIP)
                                                is
                                                between 20 -
                                                30.<br/>
                                                <Button variant={"success"} onClick={this.changeVPIP}
                                                        value={25} size={"sm"}>Select VPIP 25</Button>
                                            </p>
                                        </Col>
                                        <Col className={"d-flex flex-column text-center"}>
                                            <p className={"py-1"}><strong>Loose player</strong><br/>
                                                Opponent's range is more wider than the balanced player's. <br/>Value
                                                put in pot
                                                (VPIP)
                                                is between 30
                                                - 40.<br/>
                                                <Button variant={"success"} onClick={this.changeVPIP}
                                                        value={35} size={"sm"}>Select VPIP 35</Button>
                                            </p>
                                        </Col>
                                        <Col className={"d-flex flex-column text-center"}>
                                            <p className={"py-1"}><strong>Very loose player</strong><br/>
                                                Opponent's range is much more wider than the balanced player's. <br/>Value
                                                put
                                                in
                                                pot
                                                (VPIP) is
                                                between 40
                                                - 60.<br/>
                                                <Button variant={"success"} onClick={this.changeVPIP}
                                                        value={50} size={"sm"}>Select VPIP 50</Button>
                                            </p>
                                        </Col>
                                        <Col className={"d-flex flex-column text-center"}>
                                            <p className={"py-1"}><strong>Maniac player</strong><br/>
                                                Opponent's range is as wide as can get. <br/>Value put in pot (VPIP) is
                                                over
                                                60.<br/>
                                                <Button variant={"success"} onClick={this.changeVPIP}
                                                        value={80} size={"sm"}>Select VPIP 80</Button>
                                            </p>
                                        </Col>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </Container>
    }
}