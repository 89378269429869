import React from 'react';
import PokerCard from './PokerCard';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import {calculateEquity} from 'poker-odds';

const ranks = ['2', '3', '4', '5', '6', '7', '8', '9', 'T', 'J', 'Q', 'K', 'A'];
const suits = ['h', 'd', 'c', 's'];
const combos = [
    ["AA","AKs","AQs","AJs","ATs","A9s","A8s","A7s","A6s","A5s","A4s","A3s","A2s"],
    ["AKo","KK","KQs","KJs","KTs","K9s","K8s","K7s","K6s","K5s","K4s","K3s","K2s"],
    ["AQo","KQo","QQ","QJs","QTs","Q9s","Q8s","Q7s","Q6s","Q5s","Q4s","Q3s","Q2s"],
    ["AJo","KJo","QJo","JJ","JTs","J9s","J8s","J7s","J6s","J5s","J4s","J3s","J2s"],
    ["ATo","KTo","QTo","JTo","TT","T9s","T8s","T7s","T6s","T5s","T4s","T3s","T2s"],
    ["A9o","K9o","Q9o","J9o","T9o","99","98s","97s","96s","95s","94s","93s","92s"],
    ["A8o","K8o","Q8o","J8o","T8o","98o","88","87s","86s","85s","84s","83s","82s"],
    ["A7o","K7o","Q7o","J7o","T7o","97o","87o","77","76s","75s","74s","73s","72s"],
    ["A6o","K6o","Q6o","J6o","T6o","96o","86o","76o","66","65s","64s","63s","62s"],
    ["A5o","K5o","Q5o","J5o","T5o","95o","85o","75o","65o","55","54s","53s","52s"],
    ["A4o","K4o","Q4o","J4o","T4o","94o","84o","74o","64o","54o","44","43s","42s"],
    ["A3o","K3o","Q3o","J3o","T3o","93o","83o","73o","63o","53o","43o","33","32s"],
    ["A2o","K2o","Q2o","J2o","T2o","92o","82o","72o","62o","52o","42o","32o","22"]
];
const percentages = {
    "AA":.001,"AKs":.020,"AQs":.020,"AJs":.030,"ATs":.050,"A9s":.080,"A8s":.100,"A7s":.130,"A6s":.140,"A5s":.120,"A4s":.140,"A3s":.140,"A2s":.170,
    "AKo":.050,"KK":.010,"KQs":.030,"KJs":.030,"KTs":.060,"K9s":.100,"K8s":.160,"K7s":.190,"K6s":.240,"K5s":.250,"K4s":.250,"K3s":.260,"K2s":.260,
    "AQo":.080,"KQo":.090,"QQ":.010,"QJs":.050,"QTs":.060,"Q9s":.100,"Q8s":.190,"Q7s":.260,"Q6s":.280,"Q5s":.290,"Q4s":.290,"Q3s":.300,"Q2s":.310,
    "AJo":.120,"KJo":.140,"QJo":.150,"JJ":.020,"JTs":.060,"J9s":.110,"J8s":.170,"J7s":.270,"J6s":.330,"J5s":.350,"J4s":.370,"J3s":.370,"J2s":.380,
    "ATo":.180,"KTo":.200,"QTo":.220,"JTo":.210,"TT":.040,"T9s":.100,"T8s":.160,"T7s":.250,"T6s":.310,"T5s":.400,"T4s":.400,"T3s":.410,"T2s":.410,
    "A9o":.320,"K9o":.350,"Q9o":.360,"J9o":.340,"T9o":.310,"99":.070,"98s":.170,"97s":.240,"96s":.290,"95s":.380,"94s":.470,"93s":.470,"92s":.490,
    "A8o":.390,"K8o":.500,"Q8o":.560,"J8o":.480,"T8o":.430,"98o":.420,"88":.090,"87s":.210,"86s":.270,"85s":.330,"84s":.400,"83s":.530,"82s":.540,
    "A7o":.450,"K7o":.570,"Q7o":.660,"J7o":.640,"T7o":.590,"97o":.550,"87o":.520,"77":.120,"76s":.250,"75s":.250,"74s":.370,"73s":.450,"72s":.560,
    "A6o":.510,"K6o":.600,"Q6o":.710,"J6o":.800,"T6o":.740,"96o":.680,"86o":.610,"76o":.570,"66":.160,"65s":.270,"64s":.290,"63s":.380,"62s":.490,
    "A5o":.440,"K5o":.630,"Q5o":.750,"J5o":.820,"T5o":.890,"95o":.830,"85o":.730,"75o":.650,"65o":.580,"55":.200,"54s":.280,"53s":.320,"52s":.390,
    "A4o":.460,"K4o":.670,"Q4o":.760,"J4o":.850,"T4o":.900,"94o":.950,"84o":.880,"74o":.780,"64o":.700,"54o":.620,"44":.230,"43s":.360,"42s":.410,
    "A3o":.490,"K3o":.670,"Q3o":.770,"J3o":.860,"T3o":.920,"93o":.960,"83o":.980,"73o":.930,"63o":.810,"53o":.720,"43o":.760,"33":.230,"32s":.460,
    "A2o":.540,"K2o":.690,"Q2o":.790,"J2o":.870,"T2o":.940,"92o":.970,"82o":.990,"72o":.999,"62o":.950,"52o":.840,"42o":.860,"32o":.910,"22":.240
}

export const generateDeck = (usedCards = []) => {
    const deck = [];
    for (const rank of ranks) {
        for (const suit of suits) {
            if (usedCards.filter((card) => {
                return card === rank + suit
            }).length === 0) {
                deck.push(`${rank}${suit}`);
            }
        }
    }
    return deck;
};

export const generateCardSelection = (suit, deck, usedCards, addCard, removeCard) => {
    return orderByRanks(deck.filter((card) => {
        return card.charAt(1) === suit;
    })).map((card, i) => {
        if (usedCards.filter(c => c === card).length > 0) {
            return <PokerCard
                key={i}
                card={card}
                disabled={true}
                size="small"
                removeCard={removeCard}
            />;
        } else {
            return <PokerCard
                key={i}
                card={card}
                size="small"
                addCard={addCard}
            />;
        }
    });
}

export const orderByRanks = (cards) => {
    return cards.sort((a, b) => {
        const rankA = ranks.indexOf(a.slice(0, -1));
        const rankB = ranks.indexOf(b.slice(0, -1));
        return rankB - rankA;
    });
};

export const generateCustomVPIP = (vpip, customRange, onlyPairs = false, onlySuited = false) => {
    if (vpip <= 0) {
        return [];
    }
    const range = Object.keys(percentages).filter((combo) => {
        return percentages[combo] <= (Number(vpip) ? Number(vpip) / 100 : 1);
    });
    const origRange = Object.keys(percentages).filter((combo) => {
        return percentages[combo] <= (Number(vpip) ? Number(vpip) / 100 : 1);
    });

    if (onlyPairs) {
        origRange.forEach((handCombo) => {
            if (handCombo[0] !== handCombo[1]) {
                range.splice(range.findIndex((e) => { return e === handCombo; }), 1);
            }
        })
    }
    if (onlySuited) {
        origRange.forEach((handCombo) => {
            if (handCombo[2] === 'o') {
                range.splice(range.findIndex((e) => { return e === handCombo; }), 1);
            }
        })
    }
    if (customRange.length) {
        customRange.forEach((combo) => {
            if (!range.includes(combo)) {
                range.push(combo);
            } else {
                range.splice(range.findIndex((e) => { return e === combo; }), 1);
            }
        })
    }
    return range;
}

export const generateGrid = (range, customRange, toggleCustomRange) => {
    return (
        <div className={"range-grid text-center"}>
            {
                combos.map((comboRow, rowIndex) => {
                    return (
                        <Row key={rowIndex}>
                            {
                                comboRow.map((tile, tileIndex) => {
                                    const variant = range.includes(tile) ? 'outline-success' : 'outline-danger';
                                    const className = range.includes(tile)
                                        ? customRange.includes(tile)
                                            ? 'range-button range-button-success range-button-custom'
                                            : 'range-button range-button-success'
                                        : customRange.includes(tile)
                                            ? 'range-button range-button-danger range-button-custom'
                                            : 'range-button range-button-danger';
                                    return (
                                        <Col
                                            key={tileIndex}
                                            className={"col-range-button"}
                                        >
                                            <Button
                                                variant={variant}
                                                className={className}
                                                size={"sm"}
                                                onClick={toggleCustomRange}
                                                data-range={tile}
                                            >
                                                {tile}
                                            </Button>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    );
                })
            }
        </div>
    )
}

export const generateWinningEstimate = (heroCards, boardCards, deck, usedCards, range, simulationDifficulty) => {
    const usableCards = deck.filter((card) => {
        return !usedCards.includes(card);
    });
    const winsAndLosses = {
        wins: 0,
        ties: 0,
        count: 0,
        opponentWins: 0,
        opponentTies: 0,
        opponentCount: 0,
        handChances: [
            {name: "high card", count: 0 },
            {name: "one pair", count: 0 },
            {name: "two pair", count: 0 },
            {name: "three of a kind", count: 0 },
            {name: "straight", count: 0 },
            {name: "flush", count: 0 },
            {name: "full house", count: 0 },
            {name: "four of a kind", count: 0 },
            {name: "straight flush", count: 0 },
            {name: "royal flush", count: 0 },
        ],
        opponentHandChances: [
            {name: "high card", count: 0 },
            {name: "one pair", count: 0 },
            {name: "two pair", count: 0 },
            {name: "three of a kind", count: 0 },
            {name: "straight", count: 0 },
            {name: "flush", count: 0 },
            {name: "full house", count: 0 },
            {name: "four of a kind", count: 0 },
            {name: "straight flush", count: 0 },
            {name: "royal flush", count: 0 },
        ]
    }
    const cardCombos = [];
    range.forEach((handCombo) => {
        const value1 = handCombo[0];
        const value2 = handCombo[1];
        const type = handCombo[2] ? handCombo[2] === 'o' ? 'off_suit' : 'suited' : 'pair';
        usableCards.forEach((card1) => {
            if (card1[0] === value1) {
                usableCards.forEach((card2) => {
                    switch (type) {
                        case 'off_suit':
                            if (card2[0] === value2 && card1 !== card2 && card1[1] !== card2[1]) {
                                if (!cardCombos.some((row) => {
                                    return (row[0] === card1 && row[1] === card2) || (row[1] === card1 && row[0] === card2);
                                })) {
                                    cardCombos.push([card1, card2]);
                                }
                            }
                            break;
                        case 'suited':
                            if (card2[0] === value2 && card1 !== card2 && card1[1] === card2[1]) {
                                if (!cardCombos.some((row) => {
                                    return (row[0] === card1 && row[1] === card2) || (row[1] === card1 && row[0] === card2);
                                })) {
                                    cardCombos.push([card1, card2]);
                                }
                            }
                            break;
                        case 'pair':
                            if (card2[0] === value2 && card1 !== card2) {
                                if (!cardCombos.some((row) => {
                                    return (row[0] === card1 && row[1] === card2) || (row[1] === card1 && row[0] === card2);
                                })) {
                                    cardCombos.push([card1, card2]);
                                }
                            }
                            break;
                        default: break;
                    }
                })
            }
        });
    });
    const comboAmount = cardCombos.length;
    let simulationMultiplier = 2;
    let mathRandom = 0.8;
    cardCombos.forEach((handCombo) => {
        const hands = [heroCards, handCombo];
        try {
            let count = 100;
            switch (true) {
                case simulationDifficulty === 1: simulationMultiplier = 0.1; mathRandom = 0.9; break;
                case simulationDifficulty === 2: simulationMultiplier = 0.2; mathRandom = 0.85; break;
                case simulationDifficulty === 3: simulationMultiplier = 0.3; mathRandom = 0.8; break;
                case simulationDifficulty === 4: simulationMultiplier = 0.4; mathRandom = 0.75; break;
                case simulationDifficulty === 5: simulationMultiplier = 0.5; mathRandom = 0.7; break;
                case simulationDifficulty === 6: simulationMultiplier = 0.6; mathRandom = 0.65; break;
                case simulationDifficulty === 7: simulationMultiplier = 0.7; mathRandom = 0.6; break;
                case simulationDifficulty === 8: simulationMultiplier = 0.8; mathRandom = 0.55; break;
                case simulationDifficulty === 9: simulationMultiplier = 0.9; mathRandom = 0.5; break;
                case simulationDifficulty === 10: simulationMultiplier = 1.0; mathRandom = 0.45; break;
                default: simulationMultiplier = 0.1; mathRandom = 0.9; break;
            }
            switch (true) {
                case comboAmount <= 100: count = Math.floor(count * simulationMultiplier); break;
                case comboAmount > 100: count = Math.floor((count - 10) * simulationMultiplier); break;
                case comboAmount > 200: count = Math.floor((count - 20) * simulationMultiplier); break;
                case comboAmount > 300: count = Math.floor((count - 30) * simulationMultiplier); break;
                default: break;
            }
            if (boardCards.length === 3) {
                if (Math.random() < mathRandom) {
                    return;
                }
            }
            const equity = calculateEquity(hands, boardCards, count);
            winsAndLosses.wins += equity[0].wins;
            winsAndLosses.ties += equity[0].ties;
            winsAndLosses.opponentWins += equity[1].wins;
            winsAndLosses.opponentTies += equity[1].ties;
            winsAndLosses.handChances.forEach((rank, i) => {
                if (winsAndLosses.handChances[i] && equity[0].handChances[i]) {
                    winsAndLosses.handChances[i].count += equity[0].handChances[i].count;
                    winsAndLosses.count += equity[0].handChances[i].count;
                }
            });
            winsAndLosses.opponentHandChances.forEach((rank, i) => {
                if (winsAndLosses.opponentHandChances[i] && equity[1].handChances[i]) {
                    winsAndLosses.opponentHandChances[i].count += equity[1].handChances[i].count;
                    winsAndLosses.opponentCount += equity[1].handChances[i].count;
                }
            });
        } catch(e) {
            //skip errors
        }
    })
    return winsAndLosses;
}