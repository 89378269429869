import React from 'react';

const PokerCard = ({card, disabled = false, size, addCard = null, removeCard = null}) => {
    if (!card) {
        return;
    }
    let value = card.charAt(0);
    if (value === 'T') {
        value = '10';
    }
    const suit = card.charAt(1);
    let div = document.createElement('div');
    let symbol = document.createElement('div');
    div.classList.add('poker-card');
    symbol.classList.add('poker-card-symbol');
    switch (size) {
        case 'small':
            div.classList.add('poker-card-small');
            break;
        default:
            break;
    }
    if (disabled) {
        div.classList.add('poker-card-disabled');
    }
    switch (suit) {
        case 'h':
            div.classList.add('poker-card-hearts');
            symbol.classList.add('hearts');
            break;
        case 'd':
            div.classList.add('poker-card-diamonds');
            symbol.classList.add('diamonds');
            break;
        case 's':
            div.classList.add('poker-card-spades');
            symbol.classList.add('spades');
            break;
        case 'c':
            div.classList.add('poker-card-clubs');
            symbol.classList.add('clubs');
            break;
        default:
            break;
    }

    return <div className={div.classList.value} onClick={removeCard ? removeCard : disabled ? null : addCard} data-value={card}>
        <div className={symbol.classList.value} data-value={card}></div>
        <div className="poker-card-value" data-value={card}>{value}</div>
        <div className={symbol.classList.value + " reversed"} data-value={card}></div>
    </div>;
};

export default PokerCard;